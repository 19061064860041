////////////////////// basic stripe styles

.stripe {
	// padding: 30px;

	.row {
		padding-left: 0;
	}

	h2,
	p {
		text-align: center;
	}

	a {
		margin: 0 auto;
	}


	.bob-btn-transparent {
		margin: 0 auto;
	}
}

////////////////////// stripe-top styles

.stripe-top {
	background-color: #ffffff;

	h2 {
		color: $brand-custom;
		font-size: 27px;
	}

	p {
		font-size: 20px;
	}

	h2, p {
		letter-spacing: 0px;
	}
}



@include media-breakpoint-up(lg) {

	.stripe-top {
		h2 {
			font-size: 49px;
		}
		p {
			font-size: 20px;	
		}
		h2, p {
			letter-spacing: 2px;
		}
	}

}

////////////////////// stripe-cta styles

.stripe-cta {
	background-color: #F7F7F7;
	padding-bottom: 30px;
	padding-top: 30px;

	h2 {
		color: #2F2F2F;
		font-size: 20px;
	}
}

@include media-breakpoint-up(sm) {
	.stripe-cta {
		padding-bottom: 41px;
		padding-top: 58px;

		h2 {
			font-size: 27px;
		}
	}	
}
