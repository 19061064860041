.paragraph,
.passage {

	h2 {
		color: $brand-custom;
	}

}

.paragraph-img {

	.img-default {
		display: block;
		height: auto;
		margin: 0 auto;
		width: 120px;
	}
}

// mobile first
.paragraph-img {
	margin-bottom: 50px;

	.img-default {
		width: 100%;
		max-width: 200px;
		height: auto;
	}

	.img-align {
		float: none;
	}
}

@include media-breakpoint-up(sm) {

	.paragraph-img {

		.img-align {
			float: none;
		}
	}
}

@include media-breakpoint-up(md) {
	.paragraph-img {
		display: block;
		margin-bottom: 0;
	}


}


@include media-breakpoint-up(md) {

	.paragraph-sec {
		.paragraph-img {
			left: percentage((8 / $grid-columns));

			.img-align {
				float: left;
			}
		}

		.paragraph-copy {
			right: percentage((4 / $grid-columns));
		}
	}

	.paragraph-top,
	.paragraph-third {

		.paragraph-img {

			.img-align {
				float: right;
			}
		}
	}

	@include media-breakpoint-up(lg) {

		.paragraph-top,
		.paragraph-sec {

			.paragraph-copy {
				margin-top: 60px;
			}
		}
	}

.paragraph.paragraph-publication {
	padding: 20px 0 0 0;

	 a {
		 text-decoration: none;
		 border-bottom: 1px solid #457993;
	 }

	.paragraph-content {
		margin-bottom: 50px;
		font-size: 18px;

		p {

			&:nth-child(2), a {
				color: #457993;
			}
		}
	}
	h3 {
		font-size: 19px;
	}
}

@include media-breakpoint-up (md) {
	.paragraph.paragraph-publication {
		padding: 30px 0 0 0;

		.paragraph-content {
			margin-bottom: 100px;
		}
	}
}


}

//////////////////////paragraph publication


.paragraph.paragraph-publication {
	padding: 20px 0 0 0;

	 a {
		 text-decoration: none;
		 border-bottom: 1px solid #457993;
	 }

	.paragraph-content {
		margin-bottom: 50px;
		font-size: 18px;

		p {

			&:nth-child(2), a {
				color: #457993;
			}
		}
	}
	h3 {
		font-size: 19px;
	}
}

@include media-breakpoint-up(sm) {
	.paragraph.paragraph-publication { 
		h3 {
			font-size: 16px;
		}
	}
}

@include media-breakpoint-up (md) {
	.paragraph.paragraph-publication {
		padding: 30px 0 0 0;

		.paragraph-content {
			margin-bottom: 100px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.paragraph.paragraph-publication { 
		h3 {
			font-size: 18px;
		}
	}
}

//////////////////////
