// geo bob

// redirect
.body-redirect {
	.hidden-redirect-default-note,
	.hidden-redirect-avail-note,
	.hidden-redirect-avail-note-br {
		display: none;
	}
}

.geobob-default .body-redirect .hidden-redirect-default-note,
.geobob-XX .body-redirect .hidden-redirect-avail-note,
.geobob-BR .body-redirect .hidden-redirect-avail-note {
	display: block;
}


.body-redirect {
	.hidden-redirect-default-cancel-note,
	.hidden-redirect-avail-cancel-note,
	.hidden-redirect-avail-cancel-note-br {
		display: none;
	}
}
.geobob-default .body-redirect .hidden-redirect-default-cancel-note,
.geobob-XX .body-redirect .hidden-redirect-avail-cancel-note,
.geobob-BR .body-redirect .hidden-redirect-avail-cancel-note {
	display: block;
}



// nav
.nav-message {
	.content-default,
	.content-avail,
	.content-avail-def,
	.content-avail-br {
		display: none;
	}
}

.geobob-default .nav-message .content-default,
.geobob-available .nav-message .content-avail,
.geobob-XX .nav-message .content-avail-def,
.geobob-BR .nav-message .content-avail-br {
	display: block;
}


.content-default,
.content-avail {
	padding: 11px 0;
}

@include media-breakpoint-up(sm) {
	.nav-message {

		.content-default,
		.content-avail {
			text-align: center;
		}
	}
}


// footer

.footer-redirect {
	display: none;
}


.footer-redirect{
	.content-default,
	.content-avail,
	.content-avail-def,
	.content-avail-br {
		display: none;
	}
}
.geobob-default .footer-redirect .content-default,
.geobob-available .footer-redirect .content-avail,
.geobob-XX .footer-redirect .content-avail-def,
.geobob-BR .footer-redirect .content-avail-br {
	display: block;
}