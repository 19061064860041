.jumbotron-bg {
    // margin-top: 50px;
    background-image: url(/images/hero_lg.jpg);
    background-position: center top;
    height: 317px;
    background-size: cover;
}

.jumbotron {
    height: 585px;

    .content {
        margin: 0;
    }

    h1 {
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 1.2;
        // font-size: 47px;
        width: 220px;
        color: $brand-custom;
    }

    p {
        font-family: $font-family-goth;
        font-size: 17px;
        width: 175px;
        height: 66px;
    }

    p.subheading-desktop {
        display: none;
    }

    p.subheading-mobile {
        display: block;
    }

    // a.bob-btn-app {
    //     margin: 40px auto 0 auto;
    // }

    a.action-btn {
        margin: 0 auto 0 auto;
    }

}

.content-actions {
    margin-top: 160px;

    .content-action {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .action-btn {
            min-width: 150px;
        }

        .action-info {
            font-size: 13px;
        }
    }
}

@media only screen and (min-width: 376px) {
    .jumbotron {
        .content {
            margin: 0 8%;
        }
    }
}


@include media-breakpoint-up(sm) {

    .jumbotron {
        .content {
            margin: 50px 0 0 0;
        }

        p {
            width: 206px;
        }
    }

    .content-actions {
        margin-top: 120px;

        .content-action {

            .action-btn {
                min-width: 230px;
                margin-left: 5%;
            }

        }
    }
}


@include media-breakpoint-up(md) {
    .jumbotron-bg {
        background-image: url(/images/hero.jpg);
        height: 585px;
    }

    .jumbotron {
        height: auto;

        h1 {
            margin-top: 0px;
            font-size: 36px;
            letter-spacing: 2px;

        }

        p {
            height: auto;
            font-style: 16px;
        }

        h1,
        p {
            width: 460px;
        }

        p.subheading-desktop {
            display: block;
        }

        p.subheading-mobile {
            display: none;
        }

        .content {
            margin: 50px 0 0 0;
        }
    }


    .content-actions {
        margin-top: 30px;

        .content-action {
            display: block;

            .action-btn {
                min-width: 300px;
                margin-left: 0;
            }

            .action-info {
                font-size: 14px;
                width: 500px;
                font-weight: 400;
                margin: 30px 0 5px 0;
            }
        }
    }
}


@include media-breakpoint-up(lg) {
    .jumbotron-bg {
        background-image: url(/images/hero_lg.jpg);
        height: 70vh;
        min-height: 600px;
    }

    .jumbotron {

        .content {
            margin: 80px 10% 0 10%;
        }
    }

}


.pro-hero-img-mobile {
    display: none;
}


//////////// page_2


.page_2 {

    .pro-hero-img-mobile {
        background-image: url(/images/hero_pro_sm.jpg);
        height: 170px;
        background-position: center 16%;
        background-size: cover;
        display: block;
        // margin-top: 50px;
    }


    .jumbotron {
        height: unset;


        h1 {
            font-size: 21px;
            line-height: 1.6;
            width: 100%;
            color: #343434;
        }

        ul {
            padding: 0;
        }

        li {
            margin: 21px 0;
            list-style: none;
            padding-left: 2.4rem;
            position: relative;
            font-size: 16px;

            p {
                width: 100%;
                height: auto;
                margin: 0;
            }
        }

        li::before {
            left: 0;
            position: absolute;
        }

        ul li::before {
            content: "";
            width: 24px;
            height: 24px;
            background-image: url(/images/check@2x.png);
            background-size: cover;
        }
    }



    @include media-breakpoint-up(sm) {
        .jumbotron-bg-pro {
            height: 513px;
            background-image: url(/images/hero_pro.jpg);
            // background-position: center top;
            background-position: center 16%;
            background-size: cover;
            // margin-top: 50px;
        }

        .pro-hero-img-mobile {
            display: none;
        }

        .jumbotron {
            .content {
                margin-top: 60px;
            }

            p {
                height: 66px;
            }
        }
    }

    @include media-breakpoint-up(md) {

        .jumbotron {

            h1 {
                // font-size: 33px;
                width: 700px;
            }
        }
    }

    @include media-breakpoint-up(lg) {

        .jumbotron-bg-pro {
            background-position: center 32%;
            background-image: url(/images/hero_pro_lg.jpg);

        }

        .jumbotron {

            h1 {
                font-size: 33px;
                width: 900px;
            }
        }
    }
}