// pagetitle


div.container.pagetitle {
    overflow: visible;
}

.pagetitle {
    position: relative;

    p {
        font-size: $font-size-lg;
    }
}

.pagetitle-icon {
    position: absolute;
    z-index: -1;

    i,
    span {
        color: #f0f0f0; // $gray-lighter

    }
}

.pagetitle {
    padding-top: 50px;
    margin-bottom: 60px;
}

.pagetitle-icon {
    top: 50px;
    right: 20px;

    i,
    span {
        font-size: 110px;
    }
}

@include media-breakpoint-up(md) {

    .pagetitle {
        padding-top: 100px;
        margin-bottom: 100px;
    }

    .pagetitle-icon {
        top: 100px;
        right: 20px;

        i,
        span {
            font-size: 130px;
        }
    }
}