body {
	visibility: hidden;
	opacity: 0;

	&.show-redirect {

		.body-content {
			display: none;
		}
		.footer {
			display: none;
		}
	}

	.body-content {
		display: block;
	}
	.footer {
		display: block;
	}

	.body-redirect {
		// display: none;
		position: absolute;
		z-index: 2000;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		background: #fff;

		.container {
			height: 100%;
		}

		.container-main {
			width: 100%;
			height: 100%;

			.d-flex {
				width: 100%;
				height: 100%;

				.container-main-inner {
					width: 80%;
					max-width: 380px;

					p {
						text-align: center;
						margin-top: 20px;
					}
				}
			}
		}

		.container-second {
			position: absolute;
			top: 0;
			right: 0;
			padding: 20px;
			text-align: right;

			a {
				border-bottom: 1px solid #457993;
				color: #457993;
				text-decoration: none;
			}

		}

		.container-hidden {
			display: none;
		}

	}
}
