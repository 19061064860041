
// ********************

.passage {

  img {
    display: block;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
}

