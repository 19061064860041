// NAV
// ***

.navbar-dark {
	background-color: $brand-app;
}

.navbar-dark .navbar-nav .nav-link {
	color: rgba(255, 255, 255, 0.5);
}

.icon-bar {
	width: 22px;
	height: 2px;
	background-color: #fff;
	display: block;
	transition: all 0.2s;
	margin-top: 4px
}

.navbar-toggler {
	border: none;
	color: rgba(255, 255, 255, 1);

	// background: transparent;
}


.navbar-toggler .top-bar {
	transform: rotate(45deg);
	transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
	opacity: 0;
}

.navbar-toggler .bottom-bar {
	transform: rotate(-45deg);
	transform-origin: 10% 90%;
}

.navbar-toggler.collapsed .top-bar {
	transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
	opacity: 1;
}

.navbar-toggler.collapsed .bottom-bar {
	transform: rotate(0);
}


.nav-item {
	margin-right: 20px;

	i {
		fill: rgba(255, 255, 255, 0.5);
		top: -3px;
		right: 0px;
	}

}

.nav-link, .nav-item {

	&:hover, 
			&:active,
			&:focus {
				i {
					fill: rgba(255, 255, 255, 1);
				}
			}
}

// nav msg

.navbar-message {
	padding: 0;
}

.nav-message {
	display: none;
	width: 100%;

	// height: 50px;
	background: #e9e9e9;
	color: #404040;

	//	#gradient > $include directional(#c1cfd6; #ffffff; 45deg);

	.content-default,
	.content-avail {
		padding: 11px 0;
	}

	.btn.btn-sm {
		font-size: 1rem;
	}

	a.btn-close,
	a.btn-close:hover,
	a.btn-close:active,
	a.btn-close:focus {
		// font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
		// font-style: normal;
		font-weight: bold;
		font-size: 24px;

		line-height: 1.5;
		color: #404040;
		text-decoration: none;
		// border-bottom: 1px solid #404040;
		margin: 0 10px;
	}

	a.btn-redirect,
	a.btn-redirect:hover,
	a.btn-redirect:active,
	a.btn-redirect:focus {
		// font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
		// font-style: normal;

		line-height: 1.5;
		color: $brand-custom;
		text-decoration: none;
	}
}

// ******************** nav items for selcting languages

.navbar-lang {

	flex-direction: row;

	&:before {
		display: none;
	}
}

.navbar-lang.display-mobile {
	display: flex;
	position: absolute;
	top: 4px;
	right: 20%;
}

.navbar-lang.display-desktop {
	display: none;
}

body.page_1 {

	.nav-item-pros {
		display: none;
	}

}

body.page_2 {

	.nav-item-patients {
		display: none;
	}

}

.page_1 .navbar li>a.page_1,
.page_2 .navbar li>a.page_2,
.lang-de .navbar li>a.lang-de,
.lang-fr .navbar li>a.lang-fr,
.lang-it .navbar li>a.lang-it {

	// border-bottom: 2px solid #ddd;
	color: #fff;
	opacity: 1;
}

.page_1 .navbar li>a.page_1>i>svg,
.page_2 .navbar li>a.page_2>i>svg {
	fill: #fff;
	opacity: 1;
}



@include media-breakpoint-up(md) {


.nav-item {

	i {
		top: -3px;
		right: 5px;
	}
}

	.navbar-lang {
		&::before {
			content: "";
			display: block;
			margin: 10px 10px 0 0;
			height: 22px;
			width: 1px;
			background-color: #fff;
		}
	}

	.navbar-lang.display-mobile {
		display: none;
	}

	.navbar-lang.display-desktop {
		display: flex;
	}


}