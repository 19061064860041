.page_1 {
    .spots {
        margin: 40px 0;

        .spot {
            margin: 40px 0 0 0;

            .spot-svg {
                position: absolute;
                top: -2px;
                fill: $brand-custom;
                // left: -40px;
            }

            h2 {
                font-size: 16px;
                margin-left: 40px;
            }

            .show-desktop {
                display: none;
            }
        }
    }
}

@media only screen and (min-width: 376px) {

    .page_1 {
        .spots {
            margin: 0 8% 40px 8%;
        }
    }
  
  
}

@include media-breakpoint-up(sm) {
    .page_1 {
        .spots {
            margin: 0;
            .spot {

                h2 {
                    font-size: 14px;
                }

                .spot-svg {
                    top: -3px;
                }
            }
        }
    }
}

@include media-breakpoint-up(md) {
    .page_1 {
        .spots {
            .spot {

                h2 {
                    font-size: 20px;
                }

                i>svg {
                    width: 30px;
                    height: 30px;
                }

                .spot-svg {
                    top: 0px;
                }
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .page_1 {
        .spots {
            .spot {

                h2 {
                    // font-size: 20px;
                    margin-left: 50px;
                    width: 260px;
                }

                i>svg {
                    width: 40px;
                    height: 40px;
                }

                .spot-svg {
                    top: -5px;
                }

                .show-desktop {
                    display: block;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .page_1 {
        .spots {
            .spot {
                h2 {
                    width: 268px;
                }
            }
        }
    }
}

.page_2 {
    // spot


    input#mce-EMAIL {
        max-width: 300px;
    }

    .spot {
        position: relative;

        h3 {
            font-weight: bold;
            font-size: 18px;
        }

        a {
            text-decoration: none;
            border-bottom: 1px solid #457993;
            color: #457993;
        }

        // ************* SVG

        .spot-svg {
            margin-bottom: 18px;
            position: relative;
            height: 40px;
        }

        .center {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .spot-svg svg {
            display: block;
            margin: 0 auto;
            fill: $brand-custom;
            width: 42px;
            height: 42px;
        }

    }

    .spot-icon {
        position: absolute;
        z-index: -1;
        color: #f0f0f0; //  $gray-lighter
    }

    .spots {
        margin-top: 0;
        margin-bottom: 20px;

        div.row {
            padding-top: 0;
            padding-bottom: 0;
        }

        .row>div {
            padding-top: 30px;
            // padding-bottom: 30px;
        }
    }


    .spot.spot-resp {
        // background-color: lighten($brand-footer, 10%);

        .icon {
            width: 24px;
            padding: 20px 0 20px 0;
        }

        .icon.desktop {
            width: 30px;

        }
    }

    @include media-breakpoint-up(sm) {
        
        .spots {
            margin-top: 0;
            margin-bottom: 60px;

            .spot-svg {
                margin-top: 18px;
            }
        }
    }

    @include media-breakpoint-up(md) {

        .spots {

            overflow: visible;

            div.row {
                padding-top: 30px;
                padding-bottom: 30px;
            }

            .row>div {
                padding-top: 0;
            }
        }
    }

}