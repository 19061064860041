h1, h2 {
	font-weight: $headings-font-weight;
}

.btn {
	font-family: "Gotham SSm A", "Gotham SSm B", "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-style: normal;
	font-weight: normal;
}

.buffer-top {
	padding-bottom: 50px;
}