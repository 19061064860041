.passage, .paragraph, .stripe-top {

    @include margin-vertical($margin-vertical-mobile);
  }
  
  @include media-breakpoint-up(sm) {
    .passage, .paragraph, .stripe-top {
      @include margin-vertical($margin-vertical-tablet);
    }
  }
  
  @include media-breakpoint-up(md) {
    .passage, .paragraph, .stripe-top {
      @include margin-vertical($margin-vertical-desktop);
    }
  }